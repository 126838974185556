import React from 'react'
import './App.css'
import RouterComponent from './views/router/Router'
import { Provider as ProviderRedux } from 'react-redux'
import Store from './redux/Store'
import CookiesBar from './components/CookiesBar'
import './locales'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { WebSocketProvider } from './context/WebSocketContext'

function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <ProviderRedux store={Store}>
        {/* <WebSocketProvider> */}
        <RouterComponent />
        <CookiesBar />
        <ToastContainer autoClose={3000} />
        {/* </WebSocketProvider> */}
      </ProviderRedux>
    </QueryClientProvider>
  )
}

export default App
