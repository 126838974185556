import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/bootstrap.min.css' // Bootstrap v4.5.2
import './assets/css/index.css'
import './assets/css/root.css'
import './assets/css/likert.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import packageJson from '../package.json'

console.log(`App Version: ${packageJson.version}`)

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById('root'))

// Desative ou ative o service worker conforme necessário
serviceWorker.unregister()
